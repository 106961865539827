import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import AppRouter from "./AppRouter";
import LandingPage from "./LandingPage";
import NewsLetter from "./Pages/NewsLetter";

function App() {
  useEffect(() => {
    document.body.style.backgroundColor = "rgb(250,250,250)";
  });
  const queryClient = new QueryClient();

  return (
    <div>
      {/* <QueryClientProvider client={queryClient}>
        <AppRouter />
      </QueryClientProvider> */}
      {/* <LandingPage /> */}
      <NewsLetter />
    </div>
  );
}

export default App;
