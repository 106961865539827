import liked from "./images/liked-gray.svg";
import likedP from "./images/liked-purple.svg";
import app from "./images/applications-gray.svg";
import appP from "./images/applications-purple.svg";
import feed from "./images/myfeed-gray.svg";
import feedP from "./images/myfeed-purple.svg";
import calendarimg from "./images/calendar-gray.svg";
import calendarP from "./images/calendar-purple.svg";

export const NavPages = Object.freeze({
  myfeed: "myfeed",
  liked: "liked",
  calendar: "calendar",
  applications: "applications",
  profile: "profile",
  settings: "settings",
  support: "support",
});

export const navbarOptions = [
  {
    to: "/myfeed",
    page: NavPages.myfeed,
    img1: feed,
    img2: feedP,
    text: "My Feed",
    alt: "Feed Link",
  },
  {
    to: "/liked",
    page: NavPages.liked,
    img1: liked,
    img2: likedP,
    text: "Liked",
    alt: "Liked Link",
  },
  {
    to: "/calendar",
    page: NavPages.calendar,
    img1: calendarimg,
    img2: calendarP,
    text: "Calendar",
    alt: "Calendar Link",
  },
  {
    to: "/applications",
    page: NavPages.applications,
    img1: app,
    img2: appP,
    text: "Applications",
    alt: "Applications Link",
  },
];
