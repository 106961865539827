import React from 'react'
import live from './images/liveevent.png'

export default function NewsLetter () {
  return (
    <div className='min-h-screen text-gray-900 flex items-center justify-center'>
      <div className='p-8 max-w-2xl mx-auto'>
        <div className='flex justify-between items-center mb-8'>
          <div className='text-3xl font-bold text-black'>Jora</div>
        </div>
        <main>
          {/* Image/Icon Section */}
          <div className='flex justify-center mb-6'>
            <img src={live} alt='' className='w-auto h-45' />
          </div>
          {/* About Section */}
          <section className='text-center'>
            <h2 className='text-3xl font-bold text-gray-900 mb-4'>
              Discover Your Next Gig Here!
            </h2>
            <p className='text-gray-700 mb-8 text-left'>
              Welcome to <span className='font-semibold'>Jora</span> - LA’s
              first all-encompassing community for the music industry brought to
              you by UCLA students. The music ecosystem has long been a
              tight-knit community with several different interconnected webs
              but no central hub. However, we believe there is power within
              bringing the social community in one place. We are striving to
              create a place for artists, venues, and everyone in between to
              connect and communicate easily.{' '}
              <span className='text-md font-semibold'>
                Join our newsletter today
              </span>{' '}
              for the ability to:
            </p>
            <ul className='text-left text-gray-700 mb-8'>
              <li>⭐ View and post artist collaboration opportunities</li>
              <li>⭐ View and post gig opportunities</li>
              <li>⭐ Receive relevant music business content</li>
            </ul>
          </section>
          {/* Form Section */}
          <section className='text-center'>
            <form
              id='form'
              name='newsletter'
              method='post'
              className='flex flex-col items-center justify-center gap-y-2.5'
            >
              {/* Netlify form handling hidden field */}
              <input type='hidden' name='form-name' value='newsletter' />
              <div className='w-full mb-4'>
                <input
                  placeholder='Enter your name'
                  type='name'
                  id='name'
                  name='name'
                  required
                  className='block w-full px-4 py-3 border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 rounded-full'
                />
              </div>
              <div className='w-full mb-4'>
                <input
                  placeholder='Enter your email'
                  type='email'
                  id='email'
                  name='email'
                  required
                  className='block w-full px-4 py-3 border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 rounded-full'
                />
              </div>
              <label className='block text-gray-700 mb-2'>
                Are you a Venue or an Artist?
              </label>
              <div className='flex items-center justify-center mb-4'>
                <input
                  type='radio'
                  id='venue'
                  name='userType'
                  value='venue'
                  className='mr-2'
                />
                <label htmlFor='venue' className='mr-4'>
                  Venue
                </label>
                <input
                  type='radio'
                  id='artist'
                  name='userType'
                  value='artist'
                  className='mr-2'
                />
                <label htmlFor='artist'>Artist</label>
              </div>
              <div className='w-full flex flex-col justify-start mb-4 gap-y-3'>
                <label htmlFor='artist' className='text-left mb-2 font-semibold'>
                  *If you chose artist
                </label>
                <input
                  placeholder="Enter genre's of music"
                  type='text'
                  id='genre'
                  name='genre'
                  className='block w-full px-4 py-3 border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 rounded-full'
                />
                <input
                  placeholder="Enter spotify/apple music profile link"
                  type='text'
                  id='link'
                  name='link'
                  className='block w-full px-4 py-3 border border-gray-300 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 rounded-full'
                />
              </div>
              <button
                type='submit'
                className='rounded-full w-full px-4 py-3 bg-yellow-500 text-white font-medium hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-yellow-400 mb-2'
              >
                Subscribe
              </button>
            </form>
          </section>
        </main>
      </div>
    </div>
  )
}
